@import url(https://s3.amazonaws.com/icomoon.io/109548/ParkHolidays/style.css?bd0ll0);
@import url(https://s3.amazonaws.com/icomoon.io/109548/Owners-Area/style.css?ni4gu);
/** ICON CLASSES TO GO HERE */
.offcanvas-open {
  overflow: hidden;
}

html, body {
  width: 100%;
  height: 100%;
}

#root {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background-color:#fafafa;
}

#root > main {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

